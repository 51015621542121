import React from "react";
import autoBind from 'react-autobind';
//
import Utils from '@/components/Utils';
import config from "@/config/config";
import Globals from "@/config/Globals";
//
import CustomComponent from "@/components/CustomComponent";
import CommonLoadingView from "../commonComponents/CommonLoadingView";
import RootACLForm from "./RootACLForm";
import RootACEsTable from './RootACEsTable';
//resources
import "@/stylesheets/CommonRegistration.less";
//
import { Layout, Button, PageHeader } from 'antd';
//
export default class RootACLView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { data: {accessList: []}, firstLoad: true, isLoading: false};
    this.isNew = false;
  }
  loadResponse(aclResp) {
    console.log(aclResp)
    if (!this._isMounted) return;
    if (aclResp.statusCode == 200) { //valid data state
      this.state.data = aclResp.body;
    }else { //non-valid data state
      this.props.app.alertController.showAPIErrorAlert(null, aclResp);
      this.state.data = [];
    }
    this.state.firstLoad = false;
    this.stopLoading(false);
    this.setState(this.state);
  }
  async componentDidMount() {
    super.componentDidMount();
    document.title = `${this.props.app.themeManager.theme.applicationName} - Partition ACL`;
    if (this.state.firstLoad) {
      //Check for id
      this.isNew = (this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this) == Globals.URL_NewIndentifier);
      if (!this.isNew) this.fetchData();
      else this.setState({firstLoad: false});
    }
  }
  //API
  async fetchData() {
    this.startLoading();
    if (!this._isMounted) return;
    const aclID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    const aclResp = (await this.props.app.idm.api.partitionACL.read(aclID));
    this.loadResponse(aclResp);
  }
  //Actions handler
  handleChange(event) { Utils.defaultFormChangeHandler(event, this); }
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (formData && this.state.data.accessList.length > 0) {
      if (this.isNew) this._addACL();
      else this._saveACL();
    }
  }
  handleACEAdded(ace) {
    const accessList = this.state.data.accessList;
    accessList.push(ace);
    this.setState({ data: { ...this.state.data, accessList } });
  }
  handleACEDeleted(aceIdx) {
    const accessList = this.state.data.accessList;
    accessList.splice(aceIdx,1);
    this.setState({ data: { ...this.state.data, accessList } });
  }
  //UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.onGoingBehaviour.isAuthenticating;
    return (
      <Layout.Content className='pageContent'>
        <CommonLoadingView isLoading={isLoading} isFixed={true}/>
        <PageHeader className='pageHeader' onBack={() => window.history.back()} title={this.isNew ? 'New ACL' : `Partition ACL - ${(this.state.data.id ? this.state.data.id : 'Loading...')}`}
          extra={[<Button key="1" onClick={this.handleSubmit} type='primary'>{this.isNew ? 'Create Partition ACL' : 'Save Partition ACL'}</Button>]}/>
        <Layout.Content>
          <RootACLForm onChange={this.handleChange} editMode={true} isNew={this.isNew} data={this.state.data} {...Utils.propagateRef(this, 'form')}/>
          <RootACEsTable editMode={true} isLoading={isLoading} accessList={this.state.data?.accessList} app={this.props.app}
                         onACEAdded={this.handleACEAdded} onACEDeleted={this.handleACEDeleted}/>
        </Layout.Content>
      </Layout.Content>
    );
  }

  /*Private methods*/
  async _addACL() {
    this.startLoading();
    let obj = Object.assign({}, this.state.data);
    const addResp = await this.props.app.idm.api.partitionACL.create(obj);
    if (addResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Partition ACL created with success!");
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.partitionACLs);
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, addResp);
      this.stopLoading();
    }
  }
  async _saveACL() {
    this.startLoading();
    let obj = Object.assign({}, this.state.data);
    const updateResp = await this.props.app.idm.api.partitionACL.update(obj);
    if (updateResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Partition ACL updated with succeed!");
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.partitionACLs);
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, updateResp);
      this.stopLoading();
    }
  }
}
