import React from "react";
import autoBind from 'react-autobind';
import { Alert, Col, Row } from 'antd';
//
import CustomComponent from '@/components/CustomComponent';
//
import config from '@/config/config';
//
export default class UserDashboardView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  async componentDidMount() {
    super.componentDidMount();
    // Temporarly use dashboard to render confirming account
    if (!this.props.app.onGoingBehaviour.confirmingAccount) {
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.profile, null);
    }
  }
  render() {
    return (<>
      {true && 
        <Row type='flex' justify='center' align='center' style={{marginTop: '2%'}}>
          <Col>
            <Alert type='info' description={`Thank you for confirming your account with ${this.props.app.themeManager.theme.applicationName}. Your user account has already been activated!`} />
          </Col>  
        </Row>}
    </>)
  }
}
