import React from "react";
import autoBind from 'react-autobind';
import { Link } from "react-router-dom";
import { Layout, Menu, Alert, Row, Col } from 'antd';
import { PieChartOutlined, UserOutlined, TeamOutlined, AppstoreOutlined, CodeOutlined } from '@ant-design/icons';
//
import { isIE } from 'react-device-detect';
//
import CommonChangePasswordModal from "./views/commonComponents/CommonChangePasswordModal";
import AppRoutes from "./AppRoutes";
import IDMUserBadge from './views/commonComponents/IDMUserBadge';
import Logo from './views/commonComponents/Logo';
import CustomComponent from './components/CustomComponent';
import CommonLoadingView from './views/commonComponents/CommonLoadingView';
//
import config from "./config/config";
import Globals from "./config/Globals";
//
import "./stylesheets/AppViewController.less";
//
const { Header } = Layout;
//
export default class AuthorizedViewController extends CustomComponent {

  constructor(props) {
    super(props);
    autoBind(this);
    this.props.app.props.history.location.state = undefined; //reset history state if restarting app
    this.handleLogout = this.handleLogout.bind(this);
    document.title = this.props.app.themeManager.theme.applicationName;
    this.state = {
      collapsed: false, isSidebarVisible: true,
      isLoading: false, userObject: null,
    };
  }
  //Life cycle
  async componentDidMount() {
    super.componentDidMount();
    //Collapse logic
    if (window.innerWidth < 940) this.setState({ collapsed: true, isSidebarVisible: window.innerWidth > 650 });
    //Support future apps asking change pass
    if (this.props.app.onGoingBehaviour.changingPassword) {
      this.props.app.onGoingBehaviour.changingPassword = false;
      if (this.changePassModal) this.changePassModal.handleShow(true);
    }

    //User object from IDM
    const userObject = await this.props.app.idm.session.data.getUserObject();
    this.setState({ userObject });
  }
  //Actions
  async handleLogout() {
    this.startLoading();
    await this.props.app.idm.authenticator.logout();
    this.props.app.urlManager.pushPage("/");
  }
  handleChangePassword() { this.changePassModal.handleShow(); }
  handleOpenProfile() { this.props.app.urlManager.pushPage(config.ApplicationRoutes.profile) }
  //Collapsable siderbar
  onCollapse() { this.setState({ collapsed: !this.state.collapsed }); };
  toggleShowSidebar() { this.setState({ isSidebarVisible: !this.state.isSidebarVisible }); };
  //UI
  render() {
    return this.renderPage();
  }
  renderPage() {
    const themeAlert = this.props.app?.themeManager?.theme?.branding?.alert;
    return (
      <Layout className="container-layout">
        {themeAlert && <Alert style={{ zIndex: 9999 }} message={themeAlert?.content} type={themeAlert?.type || 'info'} banner />}
        {isIE && <Alert message={Globals.CompabilityMessage} banner />}
        {this._renderHeader()}
        <Layout>
          {this._renderUserNavigationBar()}
          <Layout>
            <Layout.Content className="main-content">
              <CommonLoadingView isLoading={this.state.isLoading} />
              <AppRoutes appRef={this} />
            </Layout.Content>
            {this._renderChangePasswordModal()}
          </Layout>
        </Layout>
      </Layout>
    );
  }
  _renderHeader() {
    return (<Header className='appHeader'>
              <Row type="flex" justify='space-between'>
                <Col> <Logo isHeading={true} app={this.props.app}/> </Col>
                <Col>
                  {this.props.app.idm.isLogged() && (
                    <IDMUserBadge user={this.state.userObject} openProfile={this.handleOpenProfile}
                      changePassword={this.handleChangePassword} logoutHandler={this.handleLogout} />
                  )}
                </Col>
              </Row>
            </Header>);

  }
  _renderUserNavigationBar() {
    // this should not happen, but it take our precautions :p
    if (!this.props.app.idm.isLogged()) return <></>;
    return this._renderPrivilegedNavigation();
  }
  _renderPrivilegedNavigation() {
    const pathName = this.props.app.props.history.location.pathname;
    //
    if (this.props.app.isRoot()) {
      return (
        <Layout.Sider theme="light" collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
          <Menu mode="inline" className="sideNavigation"
            selectedKeys={[pathName]} style={{ height: '100%' }}
            items={[
              {
                label: <Link to={config.ApplicationRoutes.homepage}>Dashboard</Link>,
                key: config.ApplicationRoutes.homepage,
                icon: <PieChartOutlined />,
              },
              {
                label: <Link to={config.ApplicationRoutes.applications}>Apps</Link>,
                key: config.ApplicationRoutes.applications,
                icon: <AppstoreOutlined />,
              },
              {
                label: <Link to={config.ApplicationRoutes.partitionACLs}>ACLs</Link>,
                key: config.ApplicationRoutes.partitionACLs,
                icon: <CodeOutlined />,
              },
              {
                label: <Link to={config.ApplicationRoutes.users}>Users</Link>,
                key: config.ApplicationRoutes.users,
                icon: <TeamOutlined />,
              },
              {
                label: <Link to={config.ApplicationRoutes.profile}>Profile</Link>,
                key: config.ApplicationRoutes.profile,
                icon: <UserOutlined />,
              }
            ]}/>
        </Layout.Sider>
      );
    }
    //
    if (this.props.app.isAdmin()) {
      return (
        <Layout.Sider theme="light" collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
          <Menu mode="inline" className="sideNavigation"
                selectedKeys={[pathName]} style={{ height: '100%' }}
                items={[
                  {
                    label: <Link to={config.ApplicationRoutes.homepage}>Dashboard</Link>,
                    key: config.ApplicationRoutes.homepage,
                    icon: <PieChartOutlined />,
                  },
                  {
                    label: <Link to={config.ApplicationRoutes.applications}>Apps</Link>,
                    key: config.ApplicationRoutes.applications,
                    icon: <AppstoreOutlined />,
                  },
                  {
                    label: <Link to={config.ApplicationRoutes.users}>Users</Link>,
                    key: config.ApplicationRoutes.users,
                    icon: <TeamOutlined />,
                  },
                  {
                    label: <Link to={config.ApplicationRoutes.profile}>Profile</Link>,
                    key: config.ApplicationRoutes.profile,
                    icon: <UserOutlined />,
                  }
                ]}/>
        </Layout.Sider>
      );
    } else return (<></>);
  }
  //Extra UI
  _renderChangePasswordModal() {
    return (<CommonChangePasswordModal ref={f => (this.changePassModal = f)} app={this.props.app} />);
  }
}
