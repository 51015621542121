import { Component } from "react";
//
export default class CustomComponent extends Component {
  constructor(props) {
    super(props);
    this._isMounted = true;
  }
  componentDidMount() { this._isMounted = true; }
  componentWillUnmount() { this._isMounted = false; }
  //Util
  startLoading() {
    this.state.isLoading = true;
    this.setState(this.state);
  }
  stopLoading(setState) {
    if (setState == undefined) setState = true;
    this.state.isLoading = false;
    if (setState) this.setState(this.state);
  }
}
