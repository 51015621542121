import React from "react";
import autoBind from 'react-autobind';
//
import Utils from '@/components/Utils';
//
import { Card, Button, Table, Tooltip, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
//props: isLoading, data, handleEdit, handleDelete
export default class CommonAccessTokensTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: null };
  }
  //Table support
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const props = { size: 'small', onChange: this.handleFilterChange, rowKey: 'id', loading: this.props.isLoading };
    const columns = [
      { title: 'Description', dataIndex: 'description', key: 'description', width: 150,
        sorter: (a, b) => a.description.localeCompare(b.description),
        sortOrder: sortedInfo.columnKey === 'description' && sortedInfo.order
      },
      { title: 'Enabled', width: 150, render: d => (d ? 'Yes' : 'No'),
        key: 'enabled', dataIndex: 'enabled',
        sorter: (a, b) => (a == b ? 0 : a ? -1 : 1),
        sortOrder: sortedInfo.columnKey === 'enabled' && sortedInfo.order
      },
      { title: 'Updated On', width: 150, dataIndex: 'updatedOn', key: 'updatedOn',
        sorter: (a, b) => a - b,
        sortOrder: sortedInfo.columnKey === 'updatedOn' && sortedInfo.order,
        render: props => Utils.getDateAndTimeOnUIFormatByTimestamp(props)
      },
      { title: 'Actions', width: 80, key: 'Actions',
        render: props => {
          return (<span className='tableButtonContainer'>
                    <Tooltip placement="bottomLeft" title='Delete Access Token'>
                      <Popconfirm title={`Do you really want to delete access token '${props.description}'?`} placement="bottomRight" onConfirm={this.props.handleDelete.bind(this, props)} okText="Yes" cancelText="No">
                        <Button variant="none" icon={<DeleteOutlined />} shape="circle"/>
                      </Popconfirm>
                    </Tooltip>{' '}
                    <Tooltip placement="bottomLeft" title='Edit Access Token'>
                      <Button variant="none" icon={<EditOutlined />} shape="circle" onClick={this.props.handleEdit.bind(this, props)}/>
                    </Tooltip>
                  </span>);
        }
      }
    ];
    return (
     <Card className='pageContentSubsection' title='Application Access Tokens' extra={
         <Button key="1" onClick={this.props.handleCreate} type='primary'>Create Access Token</Button>
       }>
       <Table dataSource={this.props.data} columns={columns} {...props} />
     </Card>
   );
  }
}
