export default {
  employerName: {
    id: 'employer.name',
    label: 'Employer name',
    type: 'text',
    partitionID: 'ca.bccsa.professional',
    required: false,
    customProps: { maxLength: 50 }
  },
  dateOfBirth: {
    id: 'dateOfBirth',
    label: 'Date of Birth',
    type: 'date',
    partitionID: 'ca.bccsa.personal',
    required: false,
    placeholder: "yyyy-mm-dd"
  },
  addressStreetZero: {
    id: 'address.street.0',
    label: 'Street Address',
    type: 'text',
    partitionID: 'ca.bccsa.personal',
    required: false,
  },
  addressStreetOne: {
    id: 'address.street.1',
    label: 'Street Address 2',
    type: 'text',
    partitionID: 'ca.bccsa.personal',
    required: false,
  },
  addressCity: {
    id: 'address.city',
    label: 'City',
    type: 'text',
    partitionID: 'ca.bccsa.personal',
    required: false,
  },
  addressPostalCode: {
    id: 'address.postalCode',
    label: 'Postal Code',
    type: 'text',
    partitionID: 'ca.bccsa.personal',
    required: false,
  },
  addressProvince: {
    id: 'address.province',
    label: 'Province',
    type: 'text',
    partitionID: 'ca.bccsa.personal',
    required: false,
  },
  addressCountry: {
    id: 'address.country',
    label: 'Country',
    type: 'text',
    partitionID: 'ca.bccsa.personal',
    required: false,
  },
  addressCountry: {
    id: 'address.country',
    label: 'Country',
    type: 'text',
    partitionID: 'ca.bccsa.personal',
    required: false,
  },
  personalPhone: {
    id: 'mobilePhone',
    label: 'Mobile Number',
    type: 'tel',
    partitionID: 'ca.bccsa.personal',
    required: false,
    customProps: {
      pattern: {
        regex: /^[\d\-\(\) ]+$/,
        message: 'Only numbers, spaces, hyphens and parentheses',
      },
    }
  },
  addressPhone: {
    id: 'address.phone',
    label: 'Other Number',
    type: 'tel',
    partitionID: 'ca.bccsa.personal',
    required: false,
    customProps: {
      pattern: {
        regex: /^[\d\-\(\) ]+$/,
        message: 'Only numbers, spaces, hyphens and parentheses',
      },
    }
  }
  // itaNumber: {
  //   id: 'itaNumber',
  //   label: 'ITA Number',
  //   type: 'text',
  //   partitionID: 'ca.bccsa.professional',
  //   required: false,
  //   customProps: { maxLength: 50 }
  // },
}
