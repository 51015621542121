//Polyfills
import "./polyfills";
//Datadog
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs'
//
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
//Components
import App from "./app/App";
import config from "./app/config/config";
//
import 'antd/dist/antd.less';
import './app/stylesheets/index.less';
import packageJSON from '../package.json';
//Log rocket
if (!process.env.REACT_APP_OFFLINE) {
  datadogRum.init({ ...config.DatadogOptions, version: `${packageJSON.version}@${process.env.COMMIT_HASH}` });
  datadogLogs.init({ ...config.DatadogOptions, trackSessionAcrossSubdomains: true, forwardConsoleLogs: "all", forwardReports: "all", version: `${packageJSON.version}@${process.env.COMMIT_HASH}` });
  datadogRum.startSessionReplayRecording();
}
//Render router with App
ReactDOM.render(
  <Router>
    <GoogleReCaptchaProvider reCaptchaKey={config.ApplicationRecaptchaKey}>
      <App/>
    </GoogleReCaptchaProvider>
  </Router>,
  document.getElementById("root")
);
