import React from "react";
import autoBind from 'react-autobind';
//Components
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
//
import Utils from '@/components/Utils';
import { IDMGlobals } from "@ikonintegration/idmclient";
import config from "@/config/config";
//resources
import "@/stylesheets/CommonLogin.less";
//
export default class CommonLoginForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.initialUser = this.props.app.urlManager.getQueryParam(IDMGlobals.URLQueryParam_UserEmail);
  }

  async handleSubmit(e) {
    e.preventDefault();
    const formData = await this.form.validateFields();
    if (formData) this.props.onLogin(formData);
  }

  //UI
  render() {
    return (
      <Form onSubmit={this.handleSubmit} className='loginForm' {...Utils.propagateRef(this, 'form')}>
        <Form.Item name="username" initialValue={this.initialUser} autoComplete="username" rules={[
            { transform: (value) => (value ? value.trim() : value) },
            { required: true, message: 'Please, type your email!' },
            { type: 'email', message: 'Please, type a valid email address!' },
          ]}
        >
          <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please, type your password!' }]}
        >
          <Input autoComplete="current-password" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button onClick={this.handleSubmit} type="primary" htmlType="submit" className="loginFormLoginButton">
            Sign in
          </Button>
          {this.props.app.themeManager.theme.branding.signupEnabled && (
            <a className="loginFormSignup" onClick={this.props.onSingup}>Create account</a>
          )}
          <a className="loginFormForgot" onClick={this.props.onForgot}>Forgot password?</a>
        </Form.Item>
      </Form>
    );
  }
}
