import React from "react";
import { Button, Row, Col, Divider, Popover, Typography, Form, Input, DatePicker, Select } from 'antd';
import autoBind from 'react-autobind';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ReactPasswordStrength from "@ikonintegration/react-password-strength";
//
import Utils from '@/components/Utils';
//
const VALIDATION_RULES = {
  LOWER: 0,
  UPPER: 1,
  NUMBER: 2,
  MATCH_EMAIL: 3,
  MIN_CHARS: 4,
  PASS_MATCH: 5
};
//props are: onSignup, onLogin, app
export default class CommonRegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.additionalInputs = this.props.app.themeManager.theme.registration;
    
    this.state = {
      isDesktop: window.innerWidth > 890,
      data: {
        password: '',
        password_verification: '',
        email: '',
        parts: {},
      },
    };
  }

  handleChange(event) {
    Utils.defaultFormChangeHandler(event, this);
  }

  handleChangeDate(fieldName) {
    return (date, dateString) => {
      Utils.defaultFormChangeHandler({
        target: {
          id: fieldName,
          value: dateString,
        },
      }, this);
    };
  }

  async handleSignup() {
    const formData = await this.form.validateFields();
    if (formData) this.props.onSignup(this.state.data);
  }

  render() {
    const faillingRules = this._calculateFaillingRules();

    return(
      <Form layout="vertical" className='registerForm' {...Utils.propagateRef(this, 'form')}>
        {this._renderBasicInformation(faillingRules)}
        {this._renderDynamicInformation()}
        {this.props.app.idm.impersonate.isImpersonatingRole() && 
          <a className={"alreadyHaveAccountLink " + (this.props.app.themeManager.theme.branding.privacyURL && this.props.app.themeManager.theme.branding.privacyURL ? "withTerms" : "")}
          onClick={this.props.onLogin}>Already have a {this.props.app.themeManager.theme.applicationName} account?</a>
        }
        <Button
            block disabled={(faillingRules && faillingRules.length > 0) || (!this.state.data.password || !this.state.data.password_verification)}
            className='registerButton' type="primary" htmlType="submit" onClick={this.handleSignup}> Sign Up </Button>
        {this._renderTermsInfo()}
      </Form>
    );
  }

  //Form actions
  handleValidation(e) { return true; }

  handlePasswordInputChange(fieldName) {
    return (event) => {
      this.setState(prevState => ({
        ...prevState,
        data: {
          ...prevState.data,
          [fieldName]: event.password,
        },
      }));
    };
  }

  /* private renders */
  _renderBasicInformation(faillingRules) {
    const editMode = true;
    const { isDesktop } = this.state;
    const colSpan = isDesktop ? 10 : 24;
    const passwordRulesPassing = !!(faillingRules && faillingRules.length == 0);
    const PasswordRulesIconComponent = passwordRulesPassing ? CheckCircleOutlined : CloseCircleOutlined;
    return (
      <>
        <Row type="flex" justify="center" align="middle">
          <Col span={colSpan}>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                { required: true, message: 'Please, type your first name!' },
                { min: 2, max: 255, message: 'First name must be between 2 and 255 characters' },
              ]}
            >
              <Input disabled={!editMode} onChange={this.handleChange} />
            </Form.Item>
          </Col>

          <Col span={colSpan} offset={isDesktop ? 2 : 0}>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[
                { required: true, message: 'Please, type your last name!' },
                { min: 2, max: 255, message: 'Last name must be between 2 and 255 characters' },
              ]}
            >
              <Input disabled={!editMode} onChange={this.handleChange} />
            </Form.Item>
          </Col>
        </Row>

        <Row type="flex" justify="start" align="middle">
          <Col span={colSpan} offset={isDesktop ? 1 : 0}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: 'Please, type your email!' },
                { min: 2, max: 255, message: 'Email name must be between 2 and 255 characters' },
                { type: 'email', message: 'Invalid email format' }
              ]}
            >
              <Input disabled={!editMode} onChange={this.handleChange} />
            </Form.Item>
          </Col>
        </Row>
        
        <Row type="flex" justify={isDesktop ? 'center' : 'start'} align="middle">
          <Col span={isDesktop ? 10 : 23}>
            <Form.Item label="Password">
              <ReactPasswordStrength
                enabledVisibityToogle
                minLength={5}
                minScore={2}
                scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                changeCallback={this.handlePasswordInputChange('password')}
                inputProps={{
                  name: 'password',
                  autoComplete: "off",
                  className: "form-control",
                  required:true,
                  disabled: !editMode,
                  'data-dd-privacy': 'mask',
                }}
              />
              <Popover
                className='passwordTooltip'
                getPopupContainer={triggerNode => triggerNode.parentNode}
                content={
                  <div style={{ marginLeft: '16px', padding: '0px 10px 0px 10px', width: '300px' }}>
                    <Typography.Title level={4}>Password rules:</Typography.Title>
                    <ul>
                      <li>Have at least one lower case character {this._renderRuleStatus(faillingRules, VALIDATION_RULES.LOWER)}</li>
                      <li>Have at least one capital letter {this._renderRuleStatus(faillingRules, VALIDATION_RULES.UPPER)}</li>
                      <li>Have at least one number {this._renderRuleStatus(faillingRules, VALIDATION_RULES.NUMBER)}</li>
                      <li>Not be the same as the account email {this._renderRuleStatus(faillingRules, VALIDATION_RULES.MATCH_EMAIL)}</li>
                      <li>Be at least 8 characters long {this._renderRuleStatus(faillingRules, VALIDATION_RULES.MIN_CHARS)}</li>
                      <li>Passwords must match {this._renderRuleStatus(faillingRules, VALIDATION_RULES.PASS_MATCH)}</li>
                    </ul>
                  </div>
                }
                visible={faillingRules && faillingRules.length > 0}
              >
                {faillingRules && <PasswordRulesIconComponent className={'passwordTooltipIcon ' + (passwordRulesPassing ? 'rulePass' : 'ruleFail')}/>}
              </Popover>
            </Form.Item>

          </Col>
          <Col span={colSpan} offset={isDesktop ? 2 : 0}>
            <Form.Item label="Password verification">
              <ReactPasswordStrength
                enabledVisibityToogle
                minLength={5}
                minScore={2}
                scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                changeCallback={this.handlePasswordInputChange('password_verification')}
                inputProps={{
                  name: 'password_verification',
                  autoComplete: "off",
                  className: "form-control",
                  required:true,
                  disabled: !editMode,
                  'data-dd-privacy': 'mask',
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderDynamicInformation() {
    const editMode = true;
    const additionalInputs = this.additionalInputs;
    if (!additionalInputs || Object.keys(additionalInputs).length <= 0) return (<></>);

    const { isDesktop } = this.state;

    return (
      <>
        <Divider orientation="left">Personal Information</Divider>
        <Row type="flex" justify="start" align="middle">
          { Object.values(additionalInputs).map( (input, index) => {
              const partID = input.partitionID.replace(/\./g,'_');
              const even = (index % 2 == 0);
              const desktopProps = (isDesktop ? { offset: (even ? 1 : 2) } : {});
              return (
                <Col span={isDesktop ? 10 : 24} {...desktopProps} key={index}>
                  {this._renderDynamicFormItem(input, partID, editMode)}
                </Col>
              );
            })
          }
        </Row>
      </>
    );
  }

  _renderDynamicFormItem(input, partID, editMode) {
    const rules = [];

    if (input.required) {
      rules.push({ required: true, message: 'This field is required!' });
    }

    if (input.type === 'date' && !input.allowFutureValue) {
      rules.push({
        validator: (field, value) => {
          return new Promise((resolve, reject) => {
            if (value && value.isAfter(new Date())) {
              reject('Cannot be a future date.');
            } else {
              resolve();
            }
          });
        }
      });
    }

    if (input?.customProps?.maxLength) {
      rules.push({
        max: input.customProps.maxLength,
        message: `Must be lower than ${input.customProps.maxLength}`,
      });
    }

    if (input?.customProps?.pattern) {
      const type = typeof input.customProps.pattern;
      const pattern = type == 'object' ? input.customProps.pattern.regex : input.customProps.pattern;
      const message = type == 'object' ? input.customProps.pattern.message : 'Invalid format';
      
      rules.push({ pattern, message });
    }

    return (
      <Form.Item
        name={`parts.${partID}.${input.id}`}
        label={input.label}
        rules={rules}
      >
        {
          input.type === 'date'
            ? (
              <DatePicker onChange={this.handleChangeDate(`parts.${partID}.${input.id}`)} style={{ width: '100%' }} />
            ) :
          input.type === 'list' ? (
              <Select showSearch options={input.customProps.values} onChange={this.handleChange} />
          ) : (
            <Input
              id={`parts.${partID}.${input.id}`}
              type={input.type}
              disabled={!editMode}
              placeholder={input.placeholder || ''}
              onChange={this.handleChange}
            />
          )
        }
      </Form.Item>
    );
  }

  _renderRuleStatus(faillingRules, ruleType) {
    if (faillingRules && faillingRules.indexOf(ruleType) != -1) return (<CloseCircleOutlined className="ruleFail"/>);
    else if (faillingRules) return (<CheckCircleOutlined className="rulePass"/>);
    else return (<></>);
  }

  _renderTermsInfo() {
    if (this.props.app.themeManager.theme.branding.privacyURL && this.props.app.themeManager.theme.branding.termsURL) {
      return (
        <>
          <Divider></Divider>
          <Row type="flex" align="center" className="termsRow">
            <Col><h6 className="termsRowLabel">
              I agree that I have read and understand the <a href={this.props.app.themeManager.theme.branding.termsURL} target="_blank">{this.props.app.themeManager.theme.applicationName} Website Terms of use</a> and <a target="_blank" href={this.props.app.themeManager.theme.branding.privacyURL}>Privacy Policy</a> and agree to be bound by both</h6>
            </Col>
          </Row>
        </>
      )
    } else return (<></>);
  }

  /* private */
  _calculateFaillingRules() {
    if (!this.state.data.password && !this.state.data.password_verification) return null;
    const faillingRules = [];
    const pass = this.state.data.password;
    const passC = this.state.data.password_verification;
    const email = this.state.data.email;

    //Check lower
    if (!(/[a-z]/.test(pass))) faillingRules.push(VALIDATION_RULES.LOWER);
    //Check upper
    if (!(/[A-Z]/.test(pass))) faillingRules.push(VALIDATION_RULES.UPPER);
    //Check number
    if (!(/[0-9]/.test(pass))) faillingRules.push(VALIDATION_RULES.NUMBER);
    //Check matching email
    if (pass && email && pass.indexOf(email) != -1) faillingRules.push(VALIDATION_RULES.MATCH_EMAIL);
    //Check min chars
    if (pass && pass.length < 8) faillingRules.push(VALIDATION_RULES.MIN_CHARS);
    //Check confirmation
    if (pass && passC && pass != passC) faillingRules.push(VALIDATION_RULES.PASS_MATCH);
    return faillingRules;
  }
}
