import React from "react";
import autoBind from 'react-autobind';
//
import CustomComponent from '@/components/CustomComponent';
//
import config from '@/config/config';
//
export default class RootDashboardView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.applications);
  }
  render() {
    return (<></>)
  }
}
