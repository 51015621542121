export default class Utils  {}
//Data handling helpers
Utils.setNestedObject = function(obj, prop, value) {
  let reference = obj;
  // console.log('before', obj, reference, prop)
  let a = prop.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
      let key = a[i];
      if (i+1 != a.length) { //check if is not last object
        let nextKey = a[i+1];
        //safe check for sub.object
        if (reference[key] == null || reference[key] == undefined) {
          if (!isNaN(parseInt(nextKey)) && parseInt(nextKey) < 100) reference = reference[key] = [];
          else reference = reference[key] = {};
        }
        else reference = reference[key];
      } else { reference[key] = value; }
  } return obj;
}
Utils.getNestedObject = function(obj, props) {
  let a = props.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
      if (obj == null || obj == undefined) return;
      let key = a[i];
      if (key in obj) { obj = obj[key]; }
      else { return; }
  } return obj;
}
Utils.safelyGetNumericNestedObject = function(obj, props) {
  return Utils.safeNumber(Utils.getNestedObject(obj,props));
}
Utils.safelySumNumericNestedValues = function(objs, props) {
  let retValue = 0;
  for (let obj of objs) {
    retValue += Utils.safelyGetNumericNestedObject(obj, props);
  } return retValue;
}
Utils.safeNumber = function(value) {
  //safe check for booleans
  if (value === true) return 1;
  //safe check for strings
  let returnValue = parseFloat(value);
  if (isNaN(returnValue)) return 0;
  return returnValue;
}
Utils.toDoubleDigit = function(str) { return String("0" + str).slice(-2); }
Utils.toDateFormat = function(str) {
  if (str != undefined) { return str.split("-").join("/"); }
  return "";
}
Utils.toCurrencyFormat = function(str) {
  if (str !== undefined) {
    str = parseFloat(str);
    if (isNaN(str)) return "0.00";
    return str.toLocaleString('en', {minimumFractionDigits: '2', maximumFractionDigits: 2});
  }
  return "0.00";
}
Utils.camelizeString = function(str) {
  return str.split(' ').map(w=> w.replace(/./, m=> m.toUpperCase())).join(' ');
}
//Currency manipulation
Utils.safelyFixCurrency = function(value, toFix) {
  if (!toFix) toFix = 2;
  let rountTo = Math.pow(10,toFix);
  return parseFloat(Math.round(value * rountTo) / rountTo).toFixed(toFix);
}


//FORM HELPER
Utils.defaultFormChangeHandler = function(event, object) {
  const targetID = event.target.id;
  let targetValue;
  //Choose where to get value
  if (event.target.type == "checkbox") { targetValue = event.target.checked; }
  else if (event.target.type == "select") { targetValue = event.target.value; }
  else { targetValue = event.target.value; }
  //set state
  object.state.data = Utils.setNestedObject(object.state.data,targetID,targetValue)
  object.setState({data: object.state.data});
}


//DATES
Utils.getMonthYearDescriptByDate = function(date) {
  const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
  return monthNames[date.getMonth() + 1] + "/" + date.getFullYear().toString().slice(2,4);
}
Utils.getMonthName = function(month) {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return monthNames[month-1];
}
Utils.setReportDateRange = function(obj) {
  obj.months = [1,2,3,4,5,6,7,8,9,10,11,12];
  //
  let date = new Date();
  obj.years = [];
  for (let i = -1; i < 2; i++) {
    obj.years.push(date.getFullYear() + i);
  }
  //default for filter
  obj.currentYear = obj.years[1];
  obj.currentPreviousMonth = date.getMonth(); //leave as index 0-11
}


//BROWSER stuff
Utils.downloadBlob = function(blobURL) {
  //let blobURL = window.URL.createObjectURL(blob);
  let tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', 'Receipt');

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
}

//REACT stuff
  //propagate ref child to get referece
Utils.propagateRef = function(parent, props) {
  return {
    ref: (_ref) => Utils.setNestedObject(parent, props, _ref)
  }
}


//Dates
Utils.getCurrentDateOnUIFormat = function(excludeDay) {
  return this.getDateOnUIFormat(new Date(), excludeDay);
}
Utils.getDateOnUIFormat = function(date, excludeDay) {
  if (!date || isNaN(date.getTime())) return '-';
  //optional day field
  if (excludeDay === undefined) excludeDay = false;
  let day = (excludeDay ? "" : ("-" + this.toDoubleDigit(date.getDate())));
  //month is index from 0 to 11
  let month = this.toDoubleDigit(date.getMonth() + 1);
  return ("" + date.getFullYear() + "-" + month + day);
}
Utils.getDateOnUIFormatByTimestamp = function(timestamp, excludeDay) {
  return this.getDateOnUIFormat(new Date(timestamp), excludeDay);
}
  //Date and time
Utils.getCurrentDateAndTimeOnUIFormat = function() {
  return this.getDateAndTimeOnUIFormat(new Date());
}
Utils.getDateAndTimeOnUIFormatByTimestamp = function(timestamp) {
  return this.getDateAndTimeOnUIFormat(new Date(timestamp));
}
Utils.getDateAndTimeOnUIFormat = function(d) {
  if (!d || isNaN(d.getTime())) return '-';
  return [this.toDoubleDigit(d.getMonth()+1), this.toDoubleDigit(d.getDate()), d.getFullYear()].join('/') +' ' +
              [this.toDoubleDigit(d.getHours()), this.toDoubleDigit(d.getMinutes())].join(':');
}

// Recaptcha
Utils.hideGoogleRecaptchaBagde = async function(maxDeep) {
  const MAXDEEP = 10;
  const el = document.getElementsByClassName('grecaptcha-badge');
  console.debug('Hide captcha')
  if (el && el[0]) el[0].style.setProperty('visibility', 'collapse');
  else if (!maxDeep || maxDeep < MAXDEEP) {
    await Utils.shortRandSleep(150);
    Utils.hideGoogleRecaptchaBagde((maxDeep ? maxDeep++ : 2));
  }
}
Utils.showGoogleRecaptchaBagde = async function(maxDeep) {
  const MAXDEEP = 10;
  const el = document.getElementsByClassName('grecaptcha-badge');
  if (el && el[0]) el[0].style.setProperty('visibility', 'inherit ');
  else if (!maxDeep || maxDeep < MAXDEEP) {
    await Utils.shortRandSleep(150);
    Utils.showGoogleRecaptchaBagde((maxDeep ? maxDeep++ : 2));
  }
}

//Thread helper
Utils.shortRandSleep = async function(min) {
  const minSleep = (!min ? 50 : min);
  const delay = (Math.random() * ((minSleep * 2) - minSleep)) + minSleep;
  return new Promise(resolve => setTimeout(resolve, delay));
}


//Connection helper
Utils.execRequests = async (reqs, failureCallback, respCallback, failIfAnyFailure) => {
  //cleanup
  for (let reqIdx of reqs) {
    if (!reqs[reqIdx]) reqs[reqIdx] = new Promise((res) => res({ statusCode: 200 }));
  }
  //Make calls concurrency
  const resps = await Promise.all(reqs);
  const failure = resps.find(resp => resp && resp.statusCode != 200);
  if (failure) {
    if (failureCallback) await failureCallback(failure);
    if (failIfAnyFailure) return false;
  }
  //
  for (let respIdx in resps) {
    if (respCallback) await respCallback(resps[respIdx], respIdx);
  }
  return true;
};