import React from 'react';
import autoBind from 'react-autobind';
import { Button, Tag, Alert, Modal, Select, Input, Steps, Spin, Result, Row, Col, Typography, Form, Divider } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { InputBox } from "react-individual-input-box";
import { WarningTwoTone, MobileOutlined, LockOutlined } from '@ant-design/icons';
//
import Utils from '@/components/Utils';
//Todo: improve support for other country codes, this is not well handled here
//app, onFetchData
export default class CommonSetupSMSMFAModal extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false, isVisible: false, currentStep: 0, userObj: null, code: '' };
    this.steps = [
      { title: 'Setup',  content: this._renderPromptPhoneNumber },
      { title: 'Confirm Phone', content: this._renderPhoneConfirmation },
      { title: 'Enabling MFA', content: this._renderEnablingMFA },
      { title: 'Done', content: this._renderCompleted }
    ];
  }

  componentDidMount() {

  }
  //Public
  async enableMFA(userObj) {
    this.setState({ isLoading: false, isVisible: true, currentStep: 0, userObj, code: ''}, () => {
      if (this.form1) this.form1.setFieldsValue({ phoneNumber: (userObj?.phoneNumber || '').replace('+1','') });
    });
  }
  //Actions
    //Modal actions
  handleAfterClose() { this.setState({ isLoading: false, isVisible: false, currentStep: 0, userObj: null }, this.props.onFetchData); }
    //Stepper actions
  async handleNext() {
    if (this.state.currentStep == 0) {
      const resp = await this.form1.validateFields();
      if (resp) {
        //if same number as userObj and is already confirmed, skip to last step
        if (resp.phoneNumber == this.state.userObj?.phoneNumber?.replace('+1', '') && this.state.userObj?.phoneConfirmationDate > 0) {
          this.setState({ currentStep: 2, code: '', isLoading: true }, this._enableMFA);
        } else {
          this.setState({ userObj: { ...this.state.userObj, phoneNumber: resp.phoneNumber }, currentStep: 1, code: '', isLoading: true }, this._validatePhoneNumber);
        }
      }
    } else if (this.state.currentStep == 1) {
      const resp = await this.form2.validateFields();
      if (resp) this.setState({ currentStep: 2, isLoading: true }, this._verifyPhone.bind(this, resp.code));
    }
  }
  async handlePrevious() { if (this.state.currentStep == 1) { this.setState({ currentStep: 0 }); } }
    //Form action
  handleCodeInput(code) { 
    this.setState({code}, () => {
      if (code && code.length == 6) this.handleNext();
    });
   }
  //UI
  render() {
    return (
      <Modal maskClosable={false} title={'Setup SMS MFA (Multi-Factor Authentication)'} visible={this.state.isVisible} confirmLoading={this.state.isLoading}
        closable={true} onCancel={this.handleAfterClose} footer={null} width={650}>
        <>
          <Steps current={this.state.currentStep} >
            {this.steps.map((item, key) => (
              <Steps.Step icon={this.state.isLoading && this.state.currentStep == key ? <LoadingOutlined spin /> : ''} key={item.title} title={item.title} />
            ))}
          </Steps>
          {this.steps.map((step, index) => {
            return (<div key={index} className="steps-content" hidden={this.state.currentStep != index}> {this.steps[index].content()} </div>);
          })}
          <Row type='flex' justify='end'>
            {this.state.currentStep > 0 ?
              (this.state.currentStep < this.steps.length - 1 ? <Button disabled={this.state.isLoading} style={{ marginRight: '10px' }} className="secondary bordered" onClick={this.handlePrevious}> Previous </Button> : <></>) : 
              (<Button disabled={this.state.isLoading} style={{ marginRight: '10px' }} className="secondary bordered" onClick={this.handleAfterClose}> Cancel </Button>)
            }
            {this.state.currentStep < this.steps.length - 1 && (<Button disabled={this.state.isLoading} className="primary solid" type="primary" onClick={this.handleNext}> Next </Button>)}
          </Row>
        </>
      </Modal>
    );
  }
  //SubRenders
  _renderPromptPhoneNumber() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form1')}>
        <Alert style={{ marginBottom: '20px', marginTop: '30px' }} message={
          <Row type="flex" justify="center" align="top" style={{ marginTop: '8px' }}>
            <Col span={1}><WarningTwoTone style={{fontSize: '30px', marginTop: '6px'}} twoToneColor='orange'/></Col>
            <Col offset={1} span={18} align='start'>
              <Typography.Text>Please, insert/confirm the phone number you're willing to receive the code whenever you want to login on your account. </Typography.Text>
              <Typography.Paragraph underline>Make sure this phone is always accessible and lost of it's property without unlinking to your account, may result in a permanent loss of your account.</Typography.Paragraph>
            </Col>
          </Row>} type="warning" />
          <Row>
            <Col span={8}>
              <Form.Item name="phoneNumber" label="Phone Number" rules={[
                { required: true, message: 'Phone number is required!' },
                { pattern: /^[\d]+$/, message: 'Invalid phone number! Only numbers are allowed' },
              ]}>
                <Input onChange={this.handleChange} suffix={<MobileOutlined/>} style={{width: '240px'}}
                       addonBefore={<Select value='+1' options={[<Select.Option key='+1' value='+1'/>]}/>}/>
              </Form.Item>
            </Col>
          </Row>
      </Form>
    );
  }
  _renderPhoneConfirmation() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form2')} className='resetForm'>
        <Row type="flex" justify="center" align="middle" style={{ marginBottom: '20px' }}>
          <Col><LockOutlined /></Col>
          <Col offset={1} align='start'>
            <Typography.Text>We've sent a SMS the number specified device.<br></br>Please, add the code your received below.</Typography.Text>
          </Col>
        </Row>
        <Row type="flex" justify="center" align="middle">
          <Col>
            <Form.Item name="code" rules={[{ required: true, validator: (rule, value, cb) => (value && value.length == 6 ? cb() : cb('Please, add the code your received on the specified number below.')) }]} className='mfaInput'>
              <InputBox size={6} onChange={this.handleCodeInput} onlyNumbers />
            </Form.Item>
          </Col>
        </Row>
        {this.state?.userObj?.phoneNumber && <Row type="flex" justify="center" align="middle" style={{ marginBottom: '30px', marginTop: '20px' }}>
          <Typography.Text strong>Phone Number:</Typography.Text>  <Tag style={{ marginLeft: '10px' }} icon={<MobileOutlined />}>+1{this.state?.userObj?.phoneNumber}</Tag>
        </Row>}
      </Form>
    );
  }
  _renderEnablingMFA() {
    let content = { status: 'info', title: 'Loading.', subTitle: 'Validating your code and enabling MFA on your account...', extra: <Spin spinning>{' '}</Spin> };
    return ( <Result {...content} /> );
  }
  _renderCompleted() {
    let content = {
      status: 'success', title: 'MFA Enabled',
      subTitle: 'Your account is now protected by MFA (Multi-Factor Authentication), and upon on next login, you will receive a notification on the specified phone number with a 6 digits code, that is required on each login and valid for one time.'
    };
    return (
      <Result {...content} extra={<Row type="flex" justify="center">
        <Divider />
        <Button form="summary" htmlType="submit" key="submit" type="primary" loading={this.state.isLoading} disabled={this.state.isLoading}
          onClick={this.handleAfterClose} className='purchaseModalConfirmationButton' > Done </Button>
      </Row>} />
    );
  }
  //API
  async _validatePhoneNumber() {
    const resp = await this.props.app.idm.api.user.verifyPhone(this.state.userObj.id, `+1${this.state.userObj.phoneNumber}`);
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Code sent!");
      this.setState({ isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ isLoading: false, currentStep: 0 });
    } 
  }
  async _verifyPhone(code) {
    const resp = await this.props.app.idm.api.user.confirmPhoneVerification(this.state.userObj.id, code);
    if (resp.statusCode == 200) await this._enableMFA();
    else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ isLoading: false, currentStep: 0 });
    }
  }
  async _enableMFA() {
    const resp = await this.props.app.idm.api.user.enableSMSMFA(this.state.userObj.id);
    if (resp.statusCode == 200) {
      this.setState({ isLoading: false, currentStep: 3 });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ isLoading: false, currentStep: 0 });
    }
  }
}
