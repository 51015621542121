import React from "react";
import autoBind from 'react-autobind';
import { GoogleReCaptchaProvider, withGoogleReCaptcha } from 'react-google-recaptcha-v3';
//
import Utils from '@/components/Utils';
import config from "@/config/config";
//
import Logo from '../commonComponents/Logo';
import CustomComponent from "@/components/CustomComponent";
import CommonLoadingView from "../commonComponents/CommonLoadingView";
import CommonRegistrationForm from "./CommonRegistrationForm";
import CommonBoxLinks from '../commonComponents/CommonBoxLinks';
//
import "@/stylesheets/CommonRegistration.less";
//
import { Layout, Row, Col } from 'antd';
//
class CommonRegistrationView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.additionalInputs = this.props.app.themeManager.theme.registration;
    this.state = {
      data: {
        //event if someone change this 'linkingRoles', API will prevent to register on roles that dont allow self-registering :p
        linkingRoles: [ config.IDMClientOptions.roles.USER, props.app.themeManager.theme.userRole ],
        //
        parts: {}
      }, isLoading: false};
  }
  componentDidMount() {
    document.title = `Create your ${this.props.app.themeManager.theme.applicationName} account`;
    super.componentDidMount();
    Utils.showGoogleRecaptchaBagde();
    /// ### Bugfix for reload, back buttons (browser) after registration successfull or login/logout happended
    //Reload from current URL (this helps reload, back buttons (after registration/login), to make the registration page to work again)
    this.props.app.idm.impersonate.reloadImpersonateFromCurrentURL();
    //Check if is a redirect and contains roles
    if (this.props.app.idm.impersonate.isImpersonatingRole()) {
      if (Array.isArray(this.props.app.idm.impersonate.getRole())) {
        this.state.data.linkingRoles = this.state.data.linkingRoles.concat(this.props.app.idm.impersonate.getRole());
      } else this.state.data.linkingRoles.push(this.props.app.idm.impersonate.getRole());
      console.debug('Appending role from external client - ', this.state.data.linkingRoles);
    } else console.debug('No appending role detected');
  }
  componentWillUnmount() {
    super.componentWillUnmount();
    Utils.hideGoogleRecaptchaBagde();
  }
  //Actions handlers
  handleLogin() { this.props.app.urlManager.preAuthorizationRedirect(config.ApplicationRoutes.homepage); }
  
  async handleSignup(data) {
    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        ...data,
      },
    }), this._singup);
  }

  //UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.onGoingBehaviour.isAuthenticating;
    return (
      <Layout.Content className='pageContentFullScreen'>
        <CommonLoadingView isLoading={isLoading} isFixed={true} />
        <Layout.Content className='registerContainer'>
          <Row type="flex" justify="center" align="middle">
            <Col span={18} className='registerBoxContainer' align='middle' justify="center">
              <div className='registerBox'>
                <Row>
                  <Col align="center" className='registerMainLogo'> <Logo noLink app={this.props.app}/></Col>
                </Row>
                <Row>
                  <Col align="center">
                    <h5 className="registerBoxTitle">Create your {this.props.app.themeManager.theme.applicationName} account</h5>
                  </Col>
                </Row>
                <CommonRegistrationForm app={this.props.app} onSignup={this.handleSignup} onLogin={this.handleLogin} {...Utils.propagateRef(this, 'form')}/>
              </div>
            </Col>
          </Row>
          <CommonBoxLinks width={'75%'} app={this.props.app}/>
        </Layout.Content>
      </Layout.Content>
    );
  }

  /* private API */
  async _singup() {
    this.startLoading();
    const obj = await this._getRegistrationObject();
    //Send register request
    const registrationResp = await this.props.app.idm.registration.register(obj);
    if (registrationResp.statusCode == 200 && registrationResp.body.regToken) {
      this.props.app.alertController.showSuccessAlert("You have registered with success!", "Please, check your inbox for the confirmation code.");
      this.props.app.urlManager.preAuthorizationRedirect(config.ApplicationRoutes.registrationConfirmation, null, registrationResp.body.regToken, this.state.data.email);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, registrationResp);
      this.stopLoading();
    }
  }
  async _getRegistrationObject() {
    //Build send object
    let obj = JSON.parse(JSON.stringify(this.state.data));
    //If has impersonate callback url, set to registration object
    if (this.props.app.idm.impersonate.getCallbackURL()) obj.redirectURL = this.props.app.idm.impersonate.getCallbackURL();
    //Try to get captcha
    let token = null;
    try { token = await this.props.googleReCaptchaProps.executeRecaptcha('registration'); }
    catch (e) { console.error(e); }
    if (!token) {
      this.props.app.alertController.showErrorAlert('Error!', 'Invalid captcha authentication. This might be a problem with your page, try reloading it first. If this issue persists, please, contact the support!');
      this.stopLoading();
      return;
    } obj.recaptchaToken = token;
    //Remove verification
    delete obj.password_verification;

    //Format partitions
    let parts = [];
    const additionalInputs = this.additionalInputs;
    for (let input of Object.values(additionalInputs)) {
      const partID = input.partitionID.replace(/\./g,'_');
      if (parts.indexOf(partID) == -1) {
        //set personal partition properly
        if (obj.parts && obj.parts[partID]) {
          obj.parts[input.partitionID] = {value: obj.parts[partID]};
          delete obj.parts[partID];
        }
        parts.push(partID);
      }
    } return obj;
  }
}
const CommonRegistrationViewRecaptcha = withGoogleReCaptcha(CommonRegistrationView);
export default class CommonRegistrationViewHOC extends React.Component {
    render() {
      return (
        <CommonRegistrationViewRecaptcha {...this.props}/>
      );
    }
}
