import React from "react";
import { Switch } from "react-router-dom";
import CustomRoute from "./components/CustomRoute";

//Common Auth views
//import ErrorView from "./views/ErrorView";
import CommonLoginView from "./views/authSubviews/CommonLoginView";
import CommonRegistrationView from './views/authSubviews/CommonRegistrationView';
import CommonRegistrationConfirmationView from './views/authSubviews/CommonRegistrationConfirmationView';
import CommonRegistrationConfirmationResetView from './views/authSubviews/CommonRegistrationConfirmationResetView';
import CommonResetPasswordRequestView from './views/authSubviews/CommonResetPasswordRequestView';
import CommonResetPasswordConfirmationView from './views/authSubviews/CommonResetPasswordConfirmationView';
import CommonNewPasswordSetup from './views/authSubviews/CommonNewPasswordSetup';
import CommonAuthErrorView from './views/authSubviews/CommonAuthErrorView';
import CommonRedirectView from './views/authSubviews/CommonRedirectView';
import CommonSMSMFARequestView from './views/authSubviews/CommonSMSMFARequestView';
//Common
import CommonUserProfileView from './views/commonSubviews/CommonUserProfileView';
import CommonUsersView from './views/commonSubviews/CommonUsersView';
import CommonUsersSearchView from './views/commonSubviews/CommonUsersSearchView';
//User
import UserDashboardView from "./views/userSubviews/UserDashboardView";
//Admin
import AdminDashboardView from "./views/adminSubviews/AdminDashboardView";
//Root
import RootDashboardView from "./views/rootSubviews/RootDashboardView";
import RootAppsView from "./views/rootSubviews/RootAppsView";
import RootAppView from "./views/rootSubviews/RootAppView";
import RootRoleView from "./views/rootSubviews/RootRoleView";
import RootAccessTokenView from "./views/rootSubviews/RootAccessTokenView";
import RootACLsView from "./views/rootSubviews/RootACLsView";
import RootACLView from "./views/rootSubviews/RootACLView";
//
import config from "./config/config";

export default class AppRoutes extends React.Component {
  render() {
    return this._renderPrivilegedRoutes();
  }
  _renderPrivilegedRoutes() {
    //not logged? - default landing page
    if (!this.props.appRef.props.app.idm.isLogged() || this.props.appRef.props.app.onGoingBehaviour.isAuthenticating) {
      return (
        <Switch>
          <CustomRoute path={config.ApplicationRoutes.login} exact component={CommonLoginView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.register} exact component={CommonRegistrationView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.registrationConfirmationReset} exact component={CommonRegistrationConfirmationResetView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.registrationConfirmation} exact component={CommonRegistrationConfirmationView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.registrationConfirmationMail} exact component={CommonRegistrationConfirmationView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.resetPassword} exact component={CommonResetPasswordRequestView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.resetPasswordRaw} exact component={CommonResetPasswordRequestView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.resetPasswordConfirmation} exact component={CommonResetPasswordConfirmationView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.setupPassword} exact component={CommonNewPasswordSetup} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.authError} exact component={CommonAuthErrorView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.smsMFA} exact component={CommonSMSMFARequestView} appRef={this.props.appRef} />
          <CustomRoute component={CommonLoginView} appRef={this.props.appRef}/>
        </Switch>
      );
    }
    //Logged but being redirected
    if (this.props.appRef.props.app.idm.isLogged() && this.props.appRef.props.app.onGoingBehaviour.redirectMode) {
      return (
        <Switch>
          <CustomRoute component={CommonRedirectView} appRef={this.props.appRef}/>
        </Switch>
      );
    }
    //Logged as root
    if (this.props.appRef.props.app.isRoot()) {
      /* RootRoleView needs to be before application route - RootAppView */
      return (
        <Switch>
          <CustomRoute path={config.ApplicationRoutes.homepage} exact component={RootDashboardView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.applications} exact component={RootAppsView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.accessToken} exact component={RootAccessTokenView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.role} exact component={RootRoleView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.application} exact component={RootAppView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.user} exact component={CommonUserProfileView} appRef={this.props.appRef} />
          {!config.ApplicationAdvancedSearchDisabled ?
            <CustomRoute path={config.ApplicationRoutes.users} exact component={CommonUsersSearchView} appRef={this.props.appRef} /> :
            <CustomRoute path={config.ApplicationRoutes.users} exact component={CommonUsersView} appRef={this.props.appRef} />
          }
          <CustomRoute path={config.ApplicationRoutes.profile} exact component={CommonUserProfileView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.partitionACLs} exact component={RootACLsView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.partitionACL} exact component={RootACLView} appRef={this.props.appRef} />
          <CustomRoute component={RootDashboardView} appRef={this.props.appRef} />
        </Switch>
      );
    }
    //Logged ad admin
    if (this.props.appRef.props.app.isAdmin()) {
      return (
        <Switch>
          <CustomRoute path={config.ApplicationRoutes.homepage} exact component={AdminDashboardView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.profile} exact component={CommonUserProfileView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.user} exact component={CommonUserProfileView} appRef={this.props.appRef}/>
          {!config.ApplicationAdvancedSearchDisabled ?
            <CustomRoute path={config.ApplicationRoutes.users} exact component={CommonUsersSearchView} appRef={this.props.appRef}/> :
            <CustomRoute path={config.ApplicationRoutes.users} exact component={CommonUsersView} appRef={this.props.appRef}/>
          }
          <CustomRoute component={AdminDashboardView} appRef={this.props.appRef}/>
        </Switch>
      )
    }
    //Logged as user
    if (this.props.appRef.props.app.isUser()) {
      return (
        <Switch>
          <CustomRoute path={config.ApplicationRoutes.homepage} exact component={UserDashboardView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.profile} exact component={CommonUserProfileView} appRef={this.props.appRef}/>
          <CustomRoute component={UserDashboardView} appRef={this.props.appRef}/>
        </Switch>
      );
    }
    //Unknown state
    console.log("No routes to be served :/")
    return (<Switch></Switch>);
  }
}
