import React from "react";
import autoBind from 'react-autobind';
import { Layout, Button, PageHeader, Table, Tooltip, Popconfirm } from 'antd';
import {
  DisconnectOutlined, IssuesCloseOutlined, ToolOutlined, UnlockOutlined, EditOutlined,
} from '@ant-design/icons';
//
import Utils from '@/components/Utils';
import CustomComponent from '@/components/CustomComponent';
import CommonLoadingView from "../commonComponents/CommonLoadingView";
//
import config from '@/config/config';
import Globals from '@/config/Globals'
//
import CommonSetPasswordModal from '../commonComponents/CommonSetPasswordModal';
//
export default class CommonUsersView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {data: [], sortedInfo: null, firstLoad: true, isLoading: false};
  }
  loadResponse(response) {
    if (!this._isMounted) return;
    if (response.statusCode == 200 && response.body.users) { //valid data state
      this.state.data = response.body.users;
    }else { //non-valid data state
      this.props.app.alertController.showAPIErrorAlert(null, response);
      this.state.data = [];
    }
    this.state.firstLoad = false;
    this.stopLoading(false);
    this.setState(this.state);
  }
  async componentDidMount() {
    super.componentDidMount();
    if (this.state.firstLoad)  this.fetchData();
    document.title = `${this.props.app.themeManager.theme.applicationName} - Users`;
  }
  //API
  async fetchData() {
    this.startLoading();
    if (!this._isMounted) return;
    this.loadResponse((await this.props.app.idm.api.user.listAll()));
  }
  //Actions
    //Table
  handleUserPasswordReset(userObj) { this._resetUsersPassword(userObj); }
  handleUserConfirm(userObj) { this._confirmUser(userObj); }
  handleUserEnable(userObj) {
    if (userObj.isEnabled) this._disableUser(userObj);
    else this._enableUser(userObj);
  }
  handleUserEdit(userObj) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.user, null, userObj.id); }
  handleUserPasswordSet(userObj) { this.changePassModal.handleShow(userObj.id); }
    //Other
  handleUserCreate() { this.props.app.urlManager.pushPage(config.ApplicationRoutes.user, null, Globals.URL_NewIndentifier); }
  //Filter support
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }

  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const isLoading = this.state.isLoading || this.props.app.onGoingBehaviour.isAuthenticating;
    const props = { loading: this.state.isLoading, scroll: { x: 1500 }, size: 'middle', onChange: this.handleFilterChange, rowKey: 'id' };
    const columns = [
      { title: 'Name', key: 'name', width: 150, render: d => (d.firstName ? d.firstName + ' ' + d.lastName : '-'),
        sorter: (a, b) => (a.firstName + ' ' + a.lastName).localeCompare((b.firstName + ' ' + b.lastName)),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order
      },
      { title: 'Email', width: 200, dataIndex: 'email', key: 'email',
        sorter: (a, b) => a.email.localeCompare(b.email),
        sortOrder: sortedInfo.columnKey === 'email' && sortedInfo.order,
      },
      { title: 'isEnabled', width: 150, render: d => (d.isEnabled ? 'Yes' : 'No'), key: 'isEnabled',
        sorter: (a, b) => (a.isEnabled == b.isEnabled ? 0 : a.isEnabled ? -1 : 1),
        sortOrder: sortedInfo.columnKey === 'isEnabled' && sortedInfo.order },
      { title: 'MFA Enabled', width: 150, render: d => (d.mfaEnabled ? 'Yes' : 'No'), key: 'mfaEnabled',
        sorter: (a, b) => (a.mfaEnabled == b.mfaEnabled ? 0 : a.mfaEnabled ? -1 : 1),
        sortOrder: sortedInfo.columnKey === 'mfaEnabled' && sortedInfo.order },
      { title: 'Locked', width: 150, render: d => (d.lockedUntil > Date.now() ? 'Yes' : 'No'), key: 'lockedUntil',
        sorter: (a, b) => a.lockedUntil - (b.lockedUntil),
        sortOrder: sortedInfo.columnKey === 'lockedUntil' && sortedInfo.order },
      { title: 'Confirmed', width: 150, render: d => (d.confirmationDate ? 'Yes' : 'No'), key: 'confirmationDate',
        sorter: (a, b) => a.confirmationDate - b.confirmationDate,
        sortOrder: sortedInfo.columnKey === 'confirmationDate' && sortedInfo.order },
      { title: 'Last login', width: 150, dataIndex: 'lastLogin', key: 'lastLogin',
        sorter: (a, b) => a - b,
        render: props => Utils.getDateAndTimeOnUIFormatByTimestamp(props),
        sortOrder: sortedInfo.columnKey === 'lastLogin' && sortedInfo.order },
      { title: 'Actions', width: 200, key: 'Actions', fixed: 'right',
        render: props => {
          return (<span className='tableButtonContainer'>
                    <Tooltip placement="bottomLeft" title={(!props.isEnabled ? 'Enable user': 'Disable user')}>
                      <Popconfirm title={`Do you really want to ${(props.isEnabled ? "disable" : "enable")} user '${props.firstName + ' ' + props.lastName}'?`} placement="bottomRight" onConfirm={this.handleUserEnable.bind(this, props)} okText="Yes" cancelText="No">
                        <Button variant="none" icon={<DisconnectOutlined />} shape="circle"/>
                      </Popconfirm>
                    </Tooltip>{' '}
                    <Tooltip placement="bottomLeft" title='Invalidate password'>
                      <Popconfirm title={`Do you really want to invalidate user '${props.firstName + ' ' + props.lastName}' password ?`} placement="bottomRight" onConfirm={this.handleUserPasswordReset.bind(this, props)} okText="Yes" cancelText="No">
                        <Button variant="none" icon={<IssuesCloseOutlined />} shape="circle" />
                      </Popconfirm>
                    </Tooltip>{' '}
                    <Tooltip placement="bottomLeft" title='Set password'>
                      <Popconfirm title={`Do you really want to set user '${props.firstName + ' ' + props.lastName}' a new password ?`} placement="bottomRight" onConfirm={this.handleUserPasswordSet.bind(this, props)} okText="Yes" cancelText="No">
                        <Button variant="none" icon={<ToolOutlined />} shape="circle" />
                      </Popconfirm>
                    </Tooltip>{' '}
                    {!props.confirmationDate &&
                      <>
                        <Tooltip placement="bottomLeft" title='Confirm user'>
                          <Popconfirm title={`Do you really want to confirm user '${props.firstName + ' ' + props.lastName}' ?`} placement="bottomRight" onConfirm={this.handleUserConfirm.bind(this, props)} okText="Yes" cancelText="No">
                            <Button variant="none" icon={<UnlockOutlined />} shape="circle"/>
                          </Popconfirm>
                        </Tooltip>{' '}
                      </>
                    }
                    <Tooltip placement="bottomLeft" title='Edit user'>
                      <Button variant="none" icon={<EditOutlined />} shape="circle" onClick={this.handleUserEdit.bind(this, props)}/>
                    </Tooltip>
                  </span>);
        }
      }
    ];
    //render
    return (
     <Layout.Content className='pageContent'>
       <CommonLoadingView isLoading={isLoading} isFixed={true}/>
       <CommonSetPasswordModal ref={f => (this.changePassModal = f)} app={this.props.app}/>
       <PageHeader className='pageHeader' title="Users"
         extra={[<Button key="1" onClick={this.handleUserCreate} type='primary'>Create user</Button>]}/>
       <Layout.Content>
         <Table dataSource={this.state.data} columns={columns} {...props} />
       </Layout.Content>
     </Layout.Content>
   );
  }

  /* API Calls */
  async _disableUser(userObj) {
    this.startLoading();
    const resp = await this.props.app.idm.api.user.disable(userObj.id);
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", `User '${userObj.firstName + ' ' + userObj.lastName}' disabled with succeed!`);
      this.fetchData();
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  async _enableUser(userObj) {
    this.startLoading();
    const resp = await this.props.app.idm.api.user.enable(userObj.id);
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", `User '${userObj.firstName + ' ' + userObj.lastName}' enabled with succeed!`);
      this.fetchData();
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  async _resetUsersPassword(userObj) {
    this.startLoading();
    //
    const resetResp = await this.props.app.idm.api.password.reset(userObj.email);
    if (resetResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", `User '${userObj.firstName + ' ' + userObj.lastName}' password reset with succeed!`);
      this.fetchData();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resetResp);
      this.stopLoading();
    }
  }
  async _confirmUser(userObj) {
    this.startLoading();
    //
    const resetResp = await this.props.app.idm.api.registrationConfirmation.confirmAsAdmin(userObj.email);
    if (resetResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", `User '${userObj.firstName + ' ' + userObj.lastName}' registration confirmation succeed!`);
      this.fetchData();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resetResp);
      this.stopLoading();
    }
  }
}
