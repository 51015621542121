import React from "react";
import { Row, Col } from 'antd';
//
import '@/stylesheets/CommonBoxLinks.less';
//
const config = require('@/config/config');
//
export default class CommonBoxLinks extends React.Component {
  render() {
    if (!(this.props.app.themeManager.theme.branding.privacyURL && this.props.app.themeManager.theme.branding.helpURL)) return (<></>);
    return (
      <Row type="flex" justify="center" align="middle" className='containerLinks'>
        <div className='containerBoxLinks' {...(this.props.width ? {style: {width: this.props.width}} : {})}>
          <Col span={12}>
            <a className="containerPrivacyLabel" href={this.props.app.themeManager.theme.branding.privacyURL}>Privacy policy</a>
          </Col>
          <Col span={12} align="end">
            <a className="containerHelpLabel" href={this.props.app.themeManager.theme.branding.helpURL}> Need help?</a>
          </Col>
        </div>
      </Row>
    )
  }
}
