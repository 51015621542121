import config from '../config/config';
import Utils from './Utils';
//
const urlParse = require('url-parse');
//
export default class ThemeManager {
  constructor(auth) {
    this.mapping = config.ThemeDomainsMapping;
    this.auth = auth;
    this.theme = this._initializeTheme();
  }

  _initializeTheme() {
    const theme = this._getCurrentDomainTheme();
    console.debug(`Using theme: ${theme.themeKey}`);
    const { themeKey } = theme;
    //
    const branding = require(`../themes/${themeKey}/branding.js`).default;
    const colours = require(`../themes/${themeKey}/colours.js`);
    //Update less and also antd theme
    if (window.less) window.less.modifyVars(colours);
    this._updateFavIcon(themeKey);
    //
    return {
      registration: require(`../themes/${themeKey}/registration.js`).default,
      branding, colours, ...theme,
      getLogo: (extended) => {
        if (extended) return require(`../themes/${themeKey}/logoExtended.png`).default;
        else return require(`../themes/${themeKey}/logoSimple.png`).default;
      },
      applicationName: `${branding.applicationName}` + (config.IncludesStageOnName ? ` - [${Utils.camelizeString(config.Stage)}]` : '')
    }
  }

  _getCurrentDomainTheme() {
    const curr = urlParse(window.location.href);
    for (let domain of Object.keys(this.mapping)) {
      if (domain.indexOf('*.') != -1) {
        if (curr.host.indexOf(domain.replace('*.', '')) != -1) return this.mapping[domain];
      } else if (curr.host.indexOf(domain) != -1) return this.mapping[domain];
    } return this.mapping[Object.keys(this.mapping)[0]];
  }

  _updateFavIcon(theme) {
    const favIcon = document.getElementById("favicon");
    const baseUrl = [location.protocol, '//', location.host, `/favicons/${theme}.ico`].join('');
    favIcon.href = baseUrl;
  }

}