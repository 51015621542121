export default {
  personalPhone: {
    id: 'mobilePhone',
    label: 'Mobile Number',
    type: 'tel',
    partitionID: 'ca.personal',
    required: true,
    customProps: {
      pattern: {
        regex: /^[\d\-\(\) ]+$/,
        message: 'Only numbers, spaces, hyphens and parentheses',
      },
    }
  }
}
