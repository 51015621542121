import React from "react";
import autoBind from 'react-autobind';
//
import Utils from '@/components/Utils';
import config from "@/config/config";
import Globals from "@/config/Globals";
//
import CustomComponent from "@/components/CustomComponent";
import CommonLoadingView from "../commonComponents/CommonLoadingView";
import CommonUserProfileForm from "./CommonUserProfileForm";
import CommonUserRolesTable from '../commonComponents/CommonUserRolesTable';
import CommonPartitionsTable from '../commonComponents/CommonPartitionsTable';
import CommonSetupSMSMFAModal from '../commonComponents/CommonSetupSMSMFAModal';
//resources
import "@/stylesheets/CommonUserProfile.less";
//
import { Layout, Button, PageHeader, Tag } from 'antd';
import { MobileOutlined } from '@ant-design/icons';
//
export default class CommonUserProfileView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.availablePartitions = this._getAvailablePartitions();
    this.state = {
      data: {bypassConfirmation: true, linkingRoles: [config.IDMClientOptions.roles.USER, props.app.themeManager.theme.userRole]},
      firstLoad: true, isLoading: false,
      partitionData: [], oldUser: null
     };
  }

  //life cycle
  async componentDidMount() {
    super.componentDidMount();
    //Check if new parameter set
    this.isNew = (this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this) == Globals.URL_NewIndentifier);
    document.title = `${this.props.app.themeManager.theme.applicationName} - ${(this.isNew ? 'New user' : 'User Profile')}`;
    //Load data if not new user
    if (this.state.firstLoad && !this.isNew) this.fetchData();
    else this.forceUpdate();
  }

  //API
  async fetchData() {
    this.startLoading();
    if (!this._isMounted) return;
    //Get user ID form URL, get user ID, and fetch user info
    let userID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    if (!userID) userID = this.props.app.idm.session.authorization.getUserID();
    if (!this.isNew) {
      const wantedParts = this._getAvailablePartitions(true);
      const partitions = await this.props.app.idm.api.userPartition.readSome(wantedParts, userID);
      const userObj = (await this.props.app.idm.api.user.read(userID));
      this.loadResponse(userObj, partitions);
    }
  }
  loadResponse(usrResp, partResp) {
    if (!this._isMounted) return;
    console.debug(usrResp, partResp)
    if (usrResp.statusCode == 200 && partResp.statusCode == 200) { //valid data state
      this.state.data = usrResp.body;
      this.state.oldUser = usrResp.body;
      this.state.partitionData = partResp.body.parts;
      this.state.data.parts = {};
      if (this.state.partitionData && this.state.partitionData.length > 0) {
        //For each available partition, try to find and inject
        for (let partID of this.availablePartitions) {
          const realPartID = partID.replace(/\_/g,'.');
          //Check if personal partition is present, if so, inject into user object
          const part = this.state.partitionData.find(part => (part.partID == realPartID));
          if (part) this.state.data.parts[partID] = part.value;
        }
      } else {
        //cleanup
        for (let partID of this.availablePartitions) {
          const realPartID = partID.replace(/\_/g,'.');
          //Check if personal partition is present, if so, inject into user object
          const part = this.state.partitionData.find(part => (part.partID == realPartID));
          if (part) this.state.data.parts[partID] = {};
        }
      }
    } else { //non-valid data state
      this.props.app.alertController.showAPIErrorAlert(null, usrResp, partResp);
      this.state.data = [];
      this.state.oldUser = null;
      this.state.partitionData = [];
    }
    //Check for id
    this.state.firstLoad = false;
    this.stopLoading(false);
    this.setState(this.state);
  }

  //Actions handler
  handlePartitionDelete(partObj) {}
  async handleSubmit() {
    const isValid = await this.form.validateFields();
    if (isValid) {
      const formData = this.form.getData();
      this.setState(prevState => ({ ...prevState, data: { ...prevState.data, ...formData, }}), () => {
        if (this.isNew) this._createUser();
        else this._saveUser();
      });
    }
  }
  handleMFAChange() {
    if (!this.state.data.mfaEnabled) this.smsMFAModal.enableMFA(this.state.data);
    else this._disableMFA();
  }
  handleRoleAdded(role) {
    const linkingRoles = this.state.data.linkingRoles;
    linkingRoles.push(role);
    this.setState({ data: { ...this.state.data, linkingRoles } });
  }
  handleRoleDeleted(roleIdx) {
    const linkingRoles = this.state.data.linkingRoles;
    linkingRoles.splice(roleIdx,1);
    this.setState({ data: { ...this.state.data, linkingRoles } });
  }

  //UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.onGoingBehaviour.isAuthenticating;
    //Get tags by user type
    let tags = [];
    if (this.state.data.linkingRoles && this.state.data.linkingRoles.indexOf(config.IDMClientOptions.roles.ROOT) != -1) tags.push(<Tag color="red" key='root'>Root user</Tag>);
    if (this.state.data.linkingRoles && this.state.data.linkingRoles.indexOf(config.IDMClientOptions.roles.ADMIN) != -1) tags.push(<Tag color="orange" key='admin'>Admin user</Tag>);
    if (this.isNew) tags.push(<Tag color="blue" key='new'>New user</Tag>);
    if (this.state.data && this.state.data.mfaEnabled) tags.push(<Tag key='mfa' icon={<MobileOutlined />}>MFA Enabled</Tag>);
    //
    const isCustomUser = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    //
    return (
      <Layout.Content className='pageContent'>
        <CommonLoadingView isLoading={isLoading} isFixed={true}/>
        <CommonSetupSMSMFAModal app={this.props.app} onFetchData={this.fetchData} {...Utils.propagateRef(this, 'smsMFAModal')} />
        <PageHeader className='pageHeader'
                    title="Profile" tags={tags}
                    {...(isCustomUser ? {onBack:() => window.history.back()} : {})}
                    extra={[<Button key="1" onClick={this.handleSubmit} type='primary'>
                            {this.isNew ? 'Create user' : 'Save user'}</Button>]}/>
        <Layout.Content>
          <CommonUserProfileForm editMode={true} isNew={this.isNew} app={this.props.app} handleSubmit={this.handleSubmit}
            data={this.state.data} {...Utils.propagateRef(this, 'form')} onMFAToggle={this.handleMFAChange} />
          {this.props.app.isRoot() &&
            <CommonUserRolesTable editMode={true} roles={this.state.data.linkingRoles} isLoading={isLoading} app={this.props.app}
              onRoleAdded={this.handleRoleAdded} onRoleDeleted={this.handleRoleDeleted}/>
          }
          {this.props.app.isRoot() &&
            <CommonPartitionsTable editMode={true} data={this.state.partitionData} isLoading={isLoading} handleDelete={this.handlePartitionDelete}/>
          }
        </Layout.Content>
      </Layout.Content>
    );
  }

  /* private API */
  async _saveUser() {
    let obj = this._getUserObject();
    console.debug('update', obj);
    //Check if changing password, if yes, prompt
    if (this.state.oldUser && this.state.oldUser.email != obj.email) {
      const confirmation = await this.props.app.alertController.showPromptAlert('Attention!', `User email will be updated to "${obj.email}". Are you sure you want to update it? (type the new email address to confirm)`);
      if (confirmation != obj.email) return;
    }
    this.startLoading();
    //Send update request
    const updateResp = await this.props.app.idm.api.user.update(obj);
    if (updateResp.statusCode == 200) {
      //Check if should send to confirmation page
      if (updateResp.body && updateResp.body.emailChangeConfirmationToken) {
        this.props.app.alertController.showSuccessAlert("", `Email updated with success. Please, confirm the new email by entering the code you just received!`);
        await this.props.app.idm.authenticator.logout();
        this.props.app.urlManager.pushPage(config.ApplicationRoutes.registrationConfirmation, null, updateResp.body.emailChangeConfirmationToken, obj.email); //push to main page
      } else {
        this.props.app.alertController.showSuccessAlert("", `User updated with success!`);
        this.fetchData();
      }
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, updateResp);
      this.stopLoading();
    }
  }
  async _createUser() {
    this.startLoading();
    let obj = this._getUserObject();
    console.debug('create', obj);
    //
    const resp = await this.props.app.idm.api.registration.register(obj);
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", `User created with success!`);
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.users);
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  async _disableMFA() {
    this.startLoading();
    console.debug('Disabling MFA');
    const resp = await this.props.app.idm.api.user.disableSMSMFA(this.state.data.id);
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", `MFA was disabled with success!`);
      this.fetchData();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }

  /* dynamic profile support */
  _getUserObject() {
    let obj = JSON.parse(JSON.stringify(this.state.data)); //deep copy :/
    if (!obj.parts) obj.parts = {};
    //Set partition properly, dont override unset previous values
    for (let partID of this.availablePartitions) {
      const realPartID = partID.replace(/\_/g,'.');
      //Check if personal partition is present, if so, inject into user object
      const part = this.state.partitionData.find(part => (part.partID == realPartID));
      if (part) {
        this.state.data.parts[partID] = part.value;
        obj.parts[realPartID] = { value: {...(part ? part.value : {}), ...obj.parts[partID]} };
        delete obj.parts[partID];
      } else if (obj.parts[partID]) {
        obj.parts[realPartID] = { value: {...(part ? part.value : {}), ...obj.parts[partID]} };
        delete obj.parts[partID];
      }
    }

    if (!this.isNew) delete obj.bypassConfirmation;
    
    console.log(obj)
    return obj;
  }
  _getAvailablePartitions(noSanitize) {
    let parts = [];
    const additionalInputs = this.props.app.themeManager.theme.registration;
    for (let input of Object.values(additionalInputs)) {
      const partID = (noSanitize ? input.partitionID : input.partitionID.replace(/\./g,'_'));
      if (parts.indexOf(partID) == -1) parts.push(partID);
    } return parts;
  }
}
