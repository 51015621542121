import React from "react";
import autoBind from 'react-autobind';
//
import Utils from '@/components/Utils';
import CustomComponent from '@/components/CustomComponent';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
//
import config from '@/config/config';
import Globals from '@/config/Globals'
//
import { Layout, Button, PageHeader, Table, Tooltip, Popconfirm} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
//
export default class RootACLsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {data: [], sortedInfo: null, firstLoad: true, isLoading: false};
  }

  loadResponse(response) {
    if (!this._isMounted) return;
    if (response.statusCode == 200 && response.body && Array.isArray(response.body.acls)) { //valid data state
      this.state.data = response.body.acls;
    }else { //non-valid data state
      this.props.app.alertController.showAPIErrorAlert(null, response);
      this.state.data = [];
    }
    this.state.firstLoad = false;
    this.stopLoading(false);
    this.setState(this.state);
  }
  async componentDidMount() {
    super.componentDidMount();
    if (this.state.firstLoad)  this.fetchData();
    document.title = `${this.props.app.themeManager.theme.applicationName} - Partitions ACL`;
  }
  //API
  async fetchData() {
    this.startLoading();
    if (!this._isMounted) return;
    this.loadResponse((await this.props.app.idm.api.partitionACL.listAll()));
  }
  //Actions
  handleACLDelete(aclObj) { this._deleteACL(aclObj); }
  handleACLEdit(aclObj) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.partitionACL, null, aclObj.id); }
  handleACLCreate() { this.props.app.urlManager.pushPage(config.ApplicationRoutes.partitionACL, null, Globals.URL_NewIndentifier); }
  //Table support
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  //UI
  render() {
     let { sortedInfo } = this.state;
     sortedInfo = sortedInfo || {};
     const isLoading = this.state.isLoading || this.props.app.onGoingBehaviour.isAuthenticating;
     const props = { loading: this.state.isLoading, size: 'middle', onChange: this.handleFilterChange, rowKey: 'id' };
     const columns = [
       { title: 'ID', dataIndex: 'id', key: 'id', width: 150,
         sorter: (a, b) => a.id.localeCompare(b.id),
         sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order
       },
       { title: 'Updated On', width: 150, dataIndex: 'updatedOn', key: 'updatedOn',
         sorter: (a, b) => a.updatedOn - b.updatedOn,
         sortOrder: sortedInfo.columnKey === 'updatedOn' && sortedInfo.order,
         render: props => Utils.getDateAndTimeOnUIFormatByTimestamp(props)
       },
       { title: 'Updated By', width: 150, dataIndex: 'updatedBy', key: 'updatedBy',
         sorter: (a, b) => a.updatedBy.localeCompare(b.updatedBy),
         sortOrder: sortedInfo.columnKey === 'updatedBy' && sortedInfo.order,
       },
       { title: 'Actions', width: 80, key: 'Actions',
         render: props => {
           return (<span className='tableButtonContainer'>
                     <Tooltip placement="bottomLeft" title='Delete ACE'>
                       <Popconfirm title={`Do you really want to delete ACL '${props.id}'?`} placement="bottomRight" onConfirm={this.handleACLDelete.bind(this, props)} okText="Yes" cancelText="No">
                         <Button variant="none" icon={<DeleteOutlined />} shape="circle"/>
                       </Popconfirm>
                     </Tooltip>{' '}
                     <Tooltip placement="bottomLeft" title='Edit ACE'>
                       <Button variant="none" icon={<EditOutlined />} shape="circle" onClick={this.handleACLEdit.bind(this, props)}/>
                     </Tooltip>
                   </span>);
         }
       }
     ];
     //render
     return (
       <Layout.Content className='pageContent'>
        <CommonLoadingView isLoading={isLoading} isFixed={true}/>
        <PageHeader className='pageHeader' title="Partitions ACL"
          extra={[<Button key="1" onClick={this.handleACLCreate} type='primary'>Create ACL</Button>]}/>
         <Layout.Content>
          <Table dataSource={this.state.data} columns={columns} {...props} />
         </Layout.Content>
       </Layout.Content>
    );
  }

  /*private methods*/
  async _deleteACL(aclObj) {
    this.startLoading();
    const delResp = await this.props.app.idm.api.partitionACL.delete(aclObj.id);
    if (delResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Partition ACL deleted with succeed!");
      this.fetchData();
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, delResp);
      this.stopLoading();
    }
  }
}
