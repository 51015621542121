import React from "react";
import { Button, Layout, Row, Col, Form, Input, Popover, Typography } from 'antd';
import autoBind from 'react-autobind';
import ReactPasswordStrength from "@ikonintegration/react-password-strength";
import { IDMGlobals } from "@ikonintegration/idmclient";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
//
import Logo from '../commonComponents/Logo';
import CustomComponent from "@/components/CustomComponent";
import CommonLoadingView from "../commonComponents/CommonLoadingView";
import Utils from '@/components/Utils';
//resources
import "@/stylesheets/CommonLogin.less";
//
import config from "@/config/config";
import Globals from "@/config/Globals";
//
export default class CommonResetPasswordConfirmationView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    const paramValue = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    this.state = {
      data: { username: (paramValue ? paramValue : ''), newPassword: '', passwordConfirmation: '' },
      isLoading: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = `Forgot Password Confirmation - ${this.props.app.themeManager.theme.applicationName}`;
  }

  //Actions
  handleInputPasswordChange(fieldName) {
    return (event) => {
      this.setState(prevState => ({
        ...prevState,
        data: { ...prevState.data, [fieldName]: event.password }
      }));
    };
  }
  async handleForgotPasswordConfirmation() {
    const formData = await this.form.validateFields();
    if (formData) {
      if (!this.state.data.newPassword) {
        this.props.app.alertController.showErrorAlert("Error!", "New password is required");
        return;
      }
      if (!this.state.data.passwordConfirmation) {
        this.props.app.alertController.showErrorAlert("Error!", "New password confirmation is required");
        return;
      }
      if (this.state.data.passwordConfirmation != this.state.data.newPassword) {
        this.props.app.alertController.showErrorAlert("Error!", "Passwords don't match!");
        return;
      }
      this.setState(prevState => ({
        ...prevState,
        data: { ...prevState.data, resetCode: formData.resetCode },
      }), this._forgotPasswordConfirmation);
    }
  }

  //UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.onGoingBehaviour.isAuthenticating;
    const faillingRules = this._calculateFaillingRules();
    const passwordRulesPassing = !!(faillingRules && faillingRules.length == 0);
    const PasswordRulesIconComponent = passwordRulesPassing ? CheckCircleOutlined : CloseCircleOutlined;
    return (
      <Layout.Content className='pageContentFullScreen'>
        <CommonLoadingView isLoading={isLoading} isFixed={true} />
        <Row type="flex" justify="center" align="middle" className='resetContainer'>
          <Col className='resetBoxContainer' align='middle' justify="center">
            <div className='resetBox'>
              <Row type="flex" justify="center" align="middle"> <Col> <Logo app={this.props.app}/> </Col> </Row>
              <Row type="flex" justify="center" align="middle">
                <Col> <h5 className="resetBoxTitle">Confirm your {this.props.app.themeManager.theme.applicationName}<br></br> account password reset</h5> </Col>
              </Row>
              <Form layout="vertical" {...Utils.propagateRef(this, 'form')} className='resetForm'>
                <Form.Item initialValue={this.state.data.username} name="username" label="Email"
                  rules={[
                    { required: true, message: 'This field is required' },
                    { min: 10, max: 255, message: 'Email must be between 10 and 255 characters' },
                    { type: 'email', message: 'Invalid email format' }
                  ]}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item name="resetCode" extra="Code sent to your email." label="Password reset code"
                  rules={[
                    { required: true, message: 'This field is required' },
                    { min: 6, max: 255, message: 'Code must be between 6 and 255 characters' },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item label="* New password">
                  <ReactPasswordStrength enabledVisibityToogle minLength={5} minScore={2}
                    scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                    changeCallback={this.handleInputPasswordChange('newPassword')}
                    inputProps={{ name: 'newPassword', autoComplete: "off", className: "form-control", required: true, 'data-dd-privacy': 'mask' }}
                  />
                  <Popover className='passwordTooltip' getPopupContainer={triggerNode => triggerNode.parentNode} visible={faillingRules && faillingRules.length > 0} content={
                    <div style={{ marginLeft: '16px', padding: '0px 10px 0px 10px', width: '300px' }}>
                      <Typography.Title level={4}>Password rules:</Typography.Title>
                      <ul>
                        <li>Have at least one lower case character {this._renderRuleStatus(faillingRules, Globals.VALIDATION_RULES.LOWER)}</li>
                        <li>Have at least one capital letter {this._renderRuleStatus(faillingRules, Globals.VALIDATION_RULES.UPPER)}</li>
                        <li>Have at least one number {this._renderRuleStatus(faillingRules, Globals.VALIDATION_RULES.NUMBER)}</li>
                        <li>Not be the same as the account email {this._renderRuleStatus(faillingRules, Globals.VALIDATION_RULES.MIN_CHARS)}</li>
                        <li>Be at least 8 characters long {this._renderRuleStatus(faillingRules, Globals.VALIDATION_RULES.PASS_MATCH)}</li>
                      </ul>
                    </div>
                  }>
                    {faillingRules && <PasswordRulesIconComponent className={'passwordTooltipIcon ' + (passwordRulesPassing ? 'rulePass' : 'ruleFail')} />}
                  </Popover>
                </Form.Item>
                <Form.Item label="* New password confirmation">
                  <ReactPasswordStrength enabledVisibityToogle minLength={5} minScore={2}
                    scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                    changeCallback={this.handleInputPasswordChange('passwordConfirmation')}
                    inputProps={{ name: 'passwordConfirmation', autoComplete: "off", className: "form-control", required: true, 'data-dd-privacy': 'mask' }} />
                </Form.Item>
                <Button block className='resetConfirmationButton' type="primary" htmlType="submit" onClick={this.handleForgotPasswordConfirmation}> Change password </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
  /* API private */
  async _forgotPasswordConfirmation() {
    this.startLoading();
    //
    const obj = Object.assign({}, this.state.data);
    const resetResp = await this.props.app.idm.user.confirmPasswordResetAndLogin(obj.newPassword, obj.resetCode, obj.username);
    if (!this._isMounted) return;
    this.stopLoading();
  }
  /* password input*/
  _renderRuleStatus(faillingRules, ruleType) {
    if (faillingRules && faillingRules.indexOf(ruleType) != -1) return (<CloseCircleOutlined className="ruleFail" />);
    else if (faillingRules) return (<CheckCircleOutlined className="rulePass" />);
    else return (<></>);
  }
  _calculateFaillingRules() {
    if (!(this.state.data?.newPassword?.length > 0 || this.state.data?.passwordConfirmation?.length > 0)) return null;
    const faillingRules = [];
    const pass = this.state.data.newPassword;
    const passC = this.state.data.passwordConfirmation;

    //Check lower
    if (!(/[a-z]/.test(pass))) faillingRules.push(Globals.VALIDATION_RULES.LOWER);
    //Check upper
    if (!(/[A-Z]/.test(pass))) faillingRules.push(Globals.VALIDATION_RULES.UPPER);
    //Check number
    if (!(/[0-9]/.test(pass))) faillingRules.push(Globals.VALIDATION_RULES.NUMBER);
    //Check min chars
    if (pass && pass.length < 8) faillingRules.push(Globals.VALIDATION_RULES.MIN_CHARS);
    //Check confirmation
    if ((pass && pass != passC) || !passC) faillingRules.push(Globals.VALIDATION_RULES.PASS_MATCH);
    return faillingRules;
  }
}
