export default class Globals {}
//Comp.
Globals.CompabilityMessage = 'You are using Internet Explorer to connect to this application. Some functions may not work correctly using IE. In order to have a better experience please use Chrome, Firefox or Safari.';
//UI
Globals.Table_PagingItemsPerPage = 25;
//Paths
Globals.URL_Path_ID_Placeholder = 'id';
Globals.URL_Path_ID2_Placeholder = 'id2';
Globals.URL_NewIndentifier = 'new';
//Query
Globals.URLQueryParam_RegConfirmationToken = 'regToken';
Globals.URLQueryParam_RegConfirmationCode = 'regCode';
Globals.URLQueryParam_ChangePassword = 'changePassword';
Globals.URLQueryParam_SearchTerm = 'search';
Globals.URLQueryParam_Page = 'page';
Globals.URLQueryParam_SortField = 'sort';
Globals.URLQueryParam_SortOrder = 'order';
//Apps
Globals.ApplicationEmailThemes = [ null, 'orangepiggy', 'application', 'ncsoimport', 'tcp' ];
//UI
Globals.VALIDATION_RULES = {
  LOWER: 0,
  UPPER: 1,
  NUMBER: 2,
  MATCH_EMAIL: 3,
  MIN_CHARS: 4,
  PASS_MATCH: 5
};