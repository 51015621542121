import React from "react";
import Cache from "./Cache";
import IDM from "@ikonintegration/idmclient";
import { datadogRum } from '@datadog/browser-rum';
//
import Utils from './Utils';
import AlertController from "./AlertController";
import URLManager from "./URLManager";
//
import config from './../config/config';
import packageJSON from './../../../package.json';
import Globals from './../config/Globals';
import ThemeManager from "./ThemeManager";
//
class Authenticator extends React.Component {
		constructor(props) {
			super(props);
			console.debug(`IDM mgmt ui - version: ${packageJSON.version}@${process.env.COMMIT_HASH}`);
			//
			this._sharedCache = null;
			this.alertController = null; //ref
			//comps
			this.urlManager = new URLManager(this);
			this.themeManager = new ThemeManager(this);
			//IDM
			this.idm = new IDM(this.themeManager.theme.idmKey, this.themeManager.theme.idmSecret, {
				...config.IDMClientOptions,
				roles: {
					...config.IDMClientOptions.roles,
					ADMIN: this.themeManager.theme.adminRole
				},
				externalAuthDomain: this.themeManager.theme.idmExternalAuthDomain,
				autoEnrollRole: [this.themeManager.theme.userRole, config.IDMClientOptions.roles.USER]
			});
			this.idm.sessionLoadedHandler = this.sessionLoadedHandler.bind(this);
			//Behaviour detection
			this.onGoingBehaviour = {
				isAuthenticating: false,
				changingPassword: this.urlManager.getQueryParam(Globals.URLQueryParam_ChangePassword),
				confirmingAccount: this.urlManager.getQueryParam(Globals.URLQueryParam_RegConfirmationCode),
			};
		}
	  async componentDidMount() {
			this.startLoading(true);
			this.onGoingBehaviour.redirectMode = !!this.idm.impersonate.isImpersonatingCBURL();
	    if (!this.idm.isLogged()) await this.idm.load(); // ask for revalidation if have session - will redirect
	    if (!this.idm.isLogged()) this.endLoading(true); //if we kept logged out, stop loading
	  }

		//Shortcuts on application levels
		isRoot() { return (this.idm.session.authorization ? this.idm.session.authorization.hasClaim('ROOT') : false); }
		isAdmin() { return (this.idm.session.authorization ? this.idm.session.authorization.hasClaim('ADMIN') : false); }
		isUser() { return (this.idm.session.authorization ? this.idm.session.authorization.hasClaim('USER') : false); }
		getAuthorizedUserID() { return this.idm?.session?.authorization?.getUserID(); }

		//Loading
		startLoading(reload) {
			this.onGoingBehaviour.isAuthenticating = true;
			if (reload) this.forceUpdate();
		}
		endLoading(reload) {
			this.onGoingBehaviour.isAuthenticating = false;
			if (reload) this.forceUpdate();
		}
		//Singleton
		sharedCache() {
	    if (this._sharedCache == null) { this._sharedCache = new Cache(this); }
	    return this._sharedCache;
	  }
		// IDM delegate calls
		async sessionLoadedHandler() {
			//session will be loaded by this.idm.load or by login call, both should be
    	//loading while this is called, so we dont update state when starting loading for session load
    	//reloading state will cause authorized area to be called while we haven't fully loaded the session
			this.startLoading(false);
			this._identifyUser();
			if (this.onGoingBehaviour.redirectMode) console.log('Not loading application cache due redirect nature of the authentication.');
			else await this._loadCache();
			// this.urlManager.pushPage(config.ApplicationRoutes.homepage);
			this.endLoading(true);
		}
		
		//UI
		render() {
			return (
				<>
					<AlertController {...Utils.propagateRef(this, 'alertController')}/>
					{this.idm.isLogged() && !this.onGoingBehaviour.isAuthenticating && !this.onGoingBehaviour.redirectMode ? this.renderAuthorizedView() : this.renderUnauthorizedView()}
				</>
			)
		}

		//Private
		async _identifyUser() {
			if (process.env.REACT_APP_OFFLINE) return;
			//Identify
			const userObj = await this.idm.session.data.getUserObject();
			const userID = this.getAuthorizedUserID()
			if (!userID) return;
			datadogRum.setUser({
				id: userID,
				name: userObj.firstName + ' ' + userObj.lastName,
				email: userObj.email,
				userType: (this.isAdmin() ? 'Admin' : (this.isRoot() ? 'Root' : 'User'))
			});
		}
		async _loadCache() {
			//Check if could load cache properly, if not we can't proceed :/
			//Since the user has already logged it worth trying 2/3 times so
			//we dont loose user retetion :p
			let retries = 0;
			const maxAttempts = 3;

			while (retries < maxAttempts) {
				if ((await this.sharedCache().loadCache())) break;
				await Utils.shortRandSleep();
				retries++;
			}

			//Check if reach max attempts
			if (maxAttempts == retries) {
				await this.idm.session.clearSession(); //light logout
				this.sharedCache().clearCache();
				if (this.sessionDidFailLoadHandler) this.sessionDidFailLoadHandler();
			} else {
				if (this.sessionDidLoadHandler) this.sessionDidLoadHandler();
			}
		}
};

export default Authenticator;
