import Utils from './Utils';
//
class Cache {
		constructor(auth) {
			this.auth = auth;
			this.roles = [];
			this.apps = [];
			this.isLoading = false;
		}
		async loadCache() {
			console.log('Loading cache')
			//avoid double cache loading
			if (this.isLoading) return false;
			this.isLoading = true;
			//Parallel requests
			const respAll = await Utils.execRequests([
				(this.auth.isAdmin() || this.auth.isRoot() ? this.auth.idm.api.application.listAll() : null),
				(this.auth.isAdmin() || this.auth.isRoot() ? this.auth.idm.api.role.listAll() : null),
			], null, ((resp, idx) => {
					if (idx == 0 && resp && resp.body && resp.statusCode == 200) this.apps = resp.body.apps;
					else if (idx == 1 && resp && resp.body && resp.statusCode == 200) this.roles = resp.body.roles;
			}), true);
			//
			this.isLoading = false;
			return respAll;
		}
		clearCache() {
			this.isLoading = false;
			this.roles = [];
			this.apps = [];
		}
		getRoles() { return this.roles; }
		setRoles(roles) { this.roles = roles; }
		getApps() { return this.apps; }
		setApps(apps) { this.apps = apps; }
		//Helpers
		findAppByID(appID) {
			return this.apps.find(appObj => { if (appObj.id == appID) return appObj;} );
		}
		findRoleByID(roleID) {
			return this.roles.find(roleObj => { if (roleObj.id == roleID) return roleObj;} );
		}
		findRoleByCognitoName(roleID) {
			return this.roles.find(roleObj => { if (roleObj.cognitoName == roleID) return roleObj;} );
		}
		//UI Helpers
		getDropdownRoles() {
			let roles = [];
			for (let role of this.roles) {
				roles.push({ pk: role.id, description: `${role.name} - ${this.findAppByID(role.appID).name}` });
			} return roles;
		}
};

export default Cache;
