import React from "react";
import autoBind from 'react-autobind';
//
import Utils from '@/components/Utils';
//
import { Layout, Row, Col, Form, Input, Checkbox } from 'antd';
//
export default class RootRoleForm extends React.Component {
    constructor(props) {
      super(props);
      autoBind(this);
    }

    validateFields() {
      return this.form.validateFields();
    }

    setFieldsValue(data) {
      this.form.setFieldsValue(data);
    }

    componentDidUpdate() {
      const data = this.props.data;
      if (data) this.form.setFieldsValue(data);
    }

    render() {
      const editMode = this.props.editMode;

      return(
        <Layout.Content justify="right-align">
          <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
            <Row gutter={12}>
              <Col span={6} offset={2}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    { required: true, message: 'Please, type role name!' },
                    { min: 4, max: 255, message: 'Role name must be between 4 and 255 characters' },
                  ]}
                >
                  <Input disabled={!editMode} onChange={this.props.onChange} />
                </Form.Item>
              </Col>
              <Col span={6} offset={2}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    { required: true, message: 'Please, type role description!' },
                  ]}
                >
                  <Input.TextArea disabled={!editMode} onChange={this.props.onChange} />
                </Form.Item>
              </Col>
            </Row><Row gutter={12}>
              <Col span={6} offset={2}>
                <Form.Item name="mfaRequired" label="MFA Required" valuePropName="checked">
                  <Checkbox onChange={this.props.onChange} disabled={!editMode} />
                </Form.Item>
              </Col>
              <Col span={6} offset={2}>
                <Form.Item name="registrationEnabled" label="Registration enabled" valuePropName="checked">
                  <Checkbox onChange={this.props.onChange} disabled={!editMode} />
                </Form.Item>
              </Col>
            </Row><Row gutter={12}>
              <Col span={6} offset={2}>
                <Form.Item name="readOnly" label="Read Only" valuePropName="checked">
                  <Checkbox onChange={this.props.onChange} disabled={!(editMode && this.props.data.superRole)} />
                </Form.Item>
              </Col>
              <Col span={6} offset={2}>
                <Form.Item name="superRole" label="Administrator Role" valuePropName="checked">
                  <Checkbox onChange={this.props.onChange} disabled={!editMode} />
                </Form.Item>
              </Col>
            </Row><Row gutter={12}>
              <Col span={6} offset={2}>
                <Form.Item name="createdOn" label="Created On">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6} offset={2}>
                <Form.Item name="allowFederation" label="Allow Federation" valuePropName="checked">
                  <Checkbox onChange={this.props.onChange} disabled={!editMode} />
                </Form.Item>
              </Col>
            </Row><Row gutter={12}>
              <Col span={6} offset={2}>
                <Form.Item name="updatedBy" label="Updated By">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6} offset={2}>
                <Form.Item name="updatedOn" label="Updated On">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row><Row gutter={12}>
              <Col span={6} offset={2}>
                <Form.Item name="cognitoName" label="Cognito ID">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Layout.Content>
      );
    }
}
