import React from "react";

const config = require('@/config/config');

export default class Logo extends React.Component {
  render() {
    let className = this.props.isHeading ? "headingLogo" : "bwLogo";
    if (this.props.size == 'small') className += '-small';
    if (this.props.noLink) {
      return (
        <img className={className} alt="logo" src={this.props.app.themeManager.theme.getLogo(this.props.isHeading)} />
      );
    } else return (
      <a href={config.ApplicationRoutes.login}>
        <img className={className} alt="logo" src={this.props.app.themeManager.theme.getLogo(this.props.isHeading)}/>
      </a>
    );
  }
}
